.Lang {
    display: flex;
    align-items: center;

    .spaBtn.active, .engBtn.active {

      p {
        font-weight: 700;
        transition: all 0.2s ease;
      }
    }

    button {
      background: transparent;
      border: 1px solid #3b424b;
      border-radius: 5px;

      display: flex;
      justify-content: center;
      align-items: center;

      height: 33px;
      width: 33px;

      p {
        transition: all 0.2s ease;
        font-weight: 400;
        margin: 0;
      }
    }

    & > p {
      font-size: 24px;
      font-weight: 300;
      margin: 0 10px;
    }
  }