.TxtGrid {

  margin-top: 20px;
  margin-bottom: 40px;
  padding-left: clamp(1.875rem, 0.58rem + 5.525vw, 5rem);
  padding-right: clamp(1.875rem, 0.58rem + 5.525vw, 5rem);

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;

  @media (min-width: 1280px) {
    grid-template-columns: 6fr 4fr;
    grid-template-rows: auto;

    margin-left: 20vw;
    margin-top: 20px;
    margin-bottom: 80px;
    padding-left: clamp(3.75rem, -6.25rem + 15.625vw, 12.5rem);
    padding-right: clamp(7.5rem, -8.214rem + 24.554vw, 21.25rem);
  }

  &__body {
    margin-right: 0;

    @media (min-width: 1280px) {
      margin-right: 40px;
    }

    p {
      line-height: 1.6em;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: row;

    gap: 40px;

    .Card__hover {
      display: none;
      cursor: default;
    }
  }
}
