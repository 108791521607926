.margins--regular {
    margin-left: clamped(20px, 200px, 400px, 1920px);
    margin-right: clamped(20px, 200px, 400px, 1920px);
}

h1, .h1, .title {
    font-size: clamp(2.375rem, 0.125rem + 2.813vw, 3.5rem);
}

h2, .h2, .subtitle {
    font-size: clamp(1.625rem, 0.875rem + 0.938vw, 2rem);
}

h3, .h3, .surtitle {
    font-size: clamp(0.875rem, 0.375rem + 0.625vw, 1.125rem);
}

h4, .h4, .footnote {
    font-size: clamp(0.813rem, 0.438rem + 0.469vw, 1rem);
}

h5, .h5, .smallFootnote {
    font-size: clamp(0.625rem, 0.25rem + 0.469vw, 0.813rem);
}

.menu__size {
    font-size: clamp(1.2rem, 0.375rem + 0.781vw, 1.313rem);
}

p {
    font-size: clamp(1.063rem, 0.563rem + 0.625vw, 1.313rem);
}