.CarruselCol {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 40px;
  padding-left: clamp(1.875rem, 0.58rem + 5.525vw, 5rem);
  padding-right: clamp(1.875rem, 0.58rem + 5.525vw, 5rem);

  @media (min-width: 1280px) {
    width: 60%;
    margin-left: 15vw;
    margin-top: 20px;
    margin-bottom: 40px;
    padding-left: clamp(3.75rem, -6.25rem + 15.625vw, 12.5rem);
    padding-right: clamp(3.75rem, -6.25rem + 15.625vw, 12.5rem);
  }

  .splide__arrow {
    background-color: transparent;
  }

  .splide__arrow--prev {
    left: 12em;
  }
  .splide__arrow--next {
    right: 12em;
  }

  img {
    object-fit: cover;
    width: 100%;
  }
}
