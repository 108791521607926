@use "sass:map";

/* BRAKEPOINTS */
@mixin mobile {
  @media only screen and (min-width: #{map.get($breakpoints, 'mobile')}) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (min-width: #{map.get($breakpoints, 'tablet')}) {
    @content;
  }
}

@mixin tablet-h {
  @media only screen and (min-width: #{map.get($breakpoints, 'tablet-h')}) {
    @content;
  }
}

@mixin screen-sm {
  @media only screen and (min-width: #{map.get($breakpoints, 'screen-sm')}) {
    @content;
  }
}

@mixin screen-md {
  @media only screen and (min-width: #{map.get($breakpoints, 'screen-md')}) {
    @content;
  }
}

@mixin screen-lg {
  @media only screen and (min-width: #{map.get($breakpoints, 'screen-lg')}) {
    @content;
  }
}

@mixin screen-xl {
  @media only screen and (min-width: #{map.get($breakpoints, 'screen-xl')}) {
    @content;
  }
}
