.MenuMobile {
  position: fixed;
  z-index: 10;
  top: -100%;
  left: 0;

  opacity: 0;

  height: 100vh;
  width: 100%;
  padding: clamp(3.125rem, 2.042rem + 4.622vw, 5rem) clamp(2rem, -0.195rem + 6.163vw, 3.75rem);

  background-color: #f2f3f4;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title p {
    font-size: clamp(1.75rem, 1.244rem + 2.157vw, 2.625rem);
    font-weight: 700;
    line-height: 1em;
  }

  &__btn {
    border-radius: 1000px;
    background-color: #bf868f;
    border: none;
    outline: none;
    width: 40px;
    height: 40px;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}
