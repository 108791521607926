.breakpoint__screenSm-down {
  width: 100%;
}

.HeaderMobile {
  width: 100%;
  background-color: var(--c-pri);

  button {
    outline: none;
    border: none;
  }

  &.sticky {
    .HeaderMobile__sticky {
      transform: translateY(0);
      transition: all 0.4s ease;
    }
  }

  &__sticky {
    position: fixed;
    top: 0;
    z-index: 10;
    height: 10vh;
    width: 100%;
    padding: 0 clamp(1.875rem, -0.195rem + 6.163vw, 3.75rem);

    transform: translateY(-100%);
    transition: all 0.4s ease;

    background-color: var(--c-pri);

    display: grid;
    grid-template-columns: 40px 3px 40px;
    gap: 10px;
    justify-content: center;
    align-content: center;
    justify-items: center;
    align-items: center;

    &-logo span {
      font-size: 46px;
      font-weight: 700;
    }

    &-separator span {
      display: inline-block;
      font-size: 38px;
      font-weight: 700;
      margin-bottom: 7px;
    }
  }

  &__static {
    position: static;
    height: 15vh;
    padding: 0 clamp(1.875rem, -0.195rem + 6.163vw, 3.75rem);

    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: var(--c-pri);

    .HeaderMobile__logo p {
      font-weight: 700;
      font-size: clamp(1.75rem, 1.244rem + 2.157vw, 2.625rem);
      line-height: 1em;
    }
  }

  &__menuBtn {
    width: 40px;
    height: 40px;
    border-radius: 1000px;
    background-color: #bf868f;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span {
      height: 3px;
      border-radius: 30px;
      background-color: white;
      width: 21px;
      margin: 2px 0;
    }
  }
}
