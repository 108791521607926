@import "../../styles/utils/placeholders";

.Header {
  @extend %paddings__components;

  @media (min-width: 1280px) {
    margin-left: 20vw;
  }

  &__abbr p {
    writing-mode: tb-rl;
    transform: rotate(-180deg);
    font-size: 140px;
    font-weight: 900;
    line-height: 0.6em;
    letter-spacing: -10px;
    color: #e6e7e9;
  }

  &__content {
    text-align: center;
    padding: 40px 0 0;

    @media (min-width: 1280px) {
      padding: 80px 0 0;
      text-align: left;
    }
  }

  &__sur {
    font-weight: 300;
  }

  &__title {
    font-weight: 500;
    padding-top: 0;

    @media (min-width: 1280px) {
      padding-top: 5px;
    }
  }
  &__sub {
    font-weight: 300;
    padding-top: 30px;
  }
  &__cite {
    max-width: 100%;
    font-style: italic;
    font-weight: 300;
    padding-top: 40px;
    line-height: 1.6em;

    @media (min-width: 1280px) {
      max-width: 55%;
    }
  }
  &__citeAuthor {
    max-width: 100%;
    text-align: center;
    font-weight: 300;
    padding-top: 10px;

    @media (min-width: 1280px) {
      max-width: 55%;
    }
  }

  &__author {
    font-weight: 700;
    padding-top: 60px;
  }
}
