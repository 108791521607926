@use "sass:math";
@use "sass:map";

@font-face {
  font-family: "Raleway";
  src: url("../../assets/fonts/Raleway-Thin.woff2") format("woff2"),
    url("../../assets/fonts/Raleway-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Raleway";
  src: url("../../assets/fonts/Raleway-Light.woff2") format("woff2"),
    url("../../assets/fonts/Raleway-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Raleway";
  src: url("../../assets/fonts/Raleway-Regular.woff2") format("woff2"),
    url("../../assets/fonts/Raleway-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Raleway";
  src: url("../../assets/fonts/Raleway-Medium.woff2") format("woff2"),
    url("../../assets/fonts/Raleway-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Raleway";
  src: url("../../assets/fonts/Raleway-Bold.woff2") format("woff2"),
    url("../../assets/fonts/Raleway-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Raleway";
  src: url("../../assets/fonts/Raleway-Black.woff2") format("woff2"),
    url("../../assets/fonts/Raleway-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}



$min-bp: 400px;
$max-bp: 1920px;

@function to-rems($px) {
  $rems: math.div($px, 16px) * 1rem;
  @return $rems;
}

@function rnd($number, $places: 0) {
  $n: 1;
  @if $places > 0 {
    @for $i from 1 through $places {
      $n: $n * 10;
    }
  }
  @return math.div(math.round($number * $n), $n);
}

@function clamped($min-px, $max-px, $min-bp, $max-bp) {
  $slope: math.div($max-px - $min-px, $max-bp - $min-bp);
  $slope-vw: rnd($slope * 100, 2);
  $intercept-rems: rnd(to-rems($min-px - $slope * $min-bp), 2);
  $min-rems: rnd(to-rems($min-px), 2);
  $max-rems: rnd(to-rems($max-px), 2);
  @return clamp(
    #{$min-rems},
    #{$slope-vw}vw + #{$intercept-rems},
    #{$max-rems}
  );
}

$body_font_family: setfont("family", "primary");
$body_font_weight: setfont("weight", "regular");

$secondary_font_family: setfont("family", "secondary");
