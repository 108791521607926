@import "../../styles/utils/placeholders";

.Grid {
  padding-top: 20px;
  padding-bottom: 80px;
  @extend %paddings__components;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: row;
  row-gap: 20px;
  column-gap: 20px;

  @media (min-width: 1280px) {
    margin-left: 20vw;

    grid-template-columns: repeat(4, 1fr);
    column-gap: 40px;
    row-gap: 40px;
  }
}
