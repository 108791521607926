.ImgTxt {
  margin-bottom: 80px;
  padding-left: clamp(1.875rem, 0.58rem + 5.525vw, 5rem);
  padding-right: clamp(1.875rem, 0.58rem + 5.525vw, 5rem);

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;

  @media (min-width: 1280px) {
    margin-left: 15vw;
    padding-left: clamp(3.75rem, -6.25rem + 15.625vw, 12.5rem);
    padding-right: clamp(7.5rem, -8.214rem + 24.554vw, 21.25rem);

    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    column-gap: 40px;
  }

  &__left {
    margin: 40px 0;
    border: 30px solid #e6e7e9;
    border-radius: 2px;

    @media (min-width: 1280px) {
      margin: 0;
    }

    img {
      object-fit: cover;
      width: 100%;
    }
  }

  &__right {
    p {
      line-height: 1.7em;
      margin-bottom: 40px;
    }

    .btn_pdf {
      width: 100%;
      border: 1px solid black;
      border-radius: 30px;
      padding: 10px;
      font-size: 21px;
      font-weight: 500;
      display: flex;
      justify-content: center;

      @media (min-width: 1280px) {
        width: 50%;
      }
    }
  }
}
