.GridPr2 {
  margin-bottom: 40px;
  padding-left: clamp(1.875rem, 0.58rem + 5.525vw, 5rem);
  padding-right: clamp(1.875rem, 0.58rem + 5.525vw, 5rem);

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto auto;

  gap: 10px;

  @media (min-width: 1280px) {
    margin-left: 15vw;
    padding-left: clamp(3.75rem, -6.25rem + 15.625vw, 12.5rem);
    padding-right: clamp(7.5rem, -8.214rem + 24.554vw, 21.25rem);
  }

  img {
    object-fit: cover;
    width: 100%;
  }
}
