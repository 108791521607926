.TxtBio {
  margin-top: 20px;
  margin-bottom: 80px;
  padding-left: clamp(1.875rem, 0.58rem + 5.525vw, 5rem);
  padding-right: clamp(1.875rem, 0.58rem + 5.525vw, 5rem);

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  grid-template-areas:
  "img"
  "txt";

  @media (min-width: 1280px) {
    display: grid;
    grid-template-columns: 6fr 4fr;
    grid-template-rows: auto;
    grid-template-areas:
    "txt img";

    margin-left: 15vw;
    padding-left: clamp(3.75rem, -6.25rem + 15.625vw, 12.5rem);
    padding-right: clamp(7.5rem, -8.214rem + 24.554vw, 21.25rem);
  }

  &__body {
    grid-area: txt;
    margin-right: 0;
    margin-top: 20px;

    @media (min-width: 1280px) {
      margin-right: 40px;
      margin-top: 0;
    }

    p {
      font-size: 21px;
      line-height: 1.6em;
    }
  }

  &__img {
    grid-area: img;
    width: 100%;

    img {
      object-fit: cover;
      width: 100%;
    }
  }
}
