@import "../variables/variables", "../utils/mixins";

%buttons {
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: $buttons_font_family;
  font-weight: $buttons_font_weight;
  font-size: $buttons_font_size;

  line-height: 1em;

  text-decoration: none;
  outline: none;
  border: none;
  cursor: pointer;

  padding: $buttons_padding;
  border-radius: $buttons_border_radius;

  transition: all 0.3s;

  @include tablet {
    font-size: $buttons_font_size_ipad;
    padding: $buttons_padding_ipad;
  }

  @include screen-sm {
    font-size: 12px;
    padding: $buttons_padding_ipad;
  }

  @include screen-sm {
    font-size: 12px;
    padding: $buttons_padding_desktop;
  }

  @include screen-md {
    font-size: $buttons_font_size_desktop;
    padding: $buttons_padding_desktop;
  }

  & > * {
    vertical-align: middle;

    & + * {
      margin-left: 0.25em;
    }
  }

  img {
    display: inline;
  }

  svg {
    font-size: inherit;
  }
}

%paddings__components {
  padding-left: clamp(1.875rem, 0.58rem + 5.525vw, 5rem);
  padding-right: clamp(1.875rem, 0.58rem + 5.525vw, 5rem);

  @media (min-width: 1280px) {
    padding-left: clamp(3.75rem, -6.25rem + 15.625vw, 12.5rem);
    padding-right: clamp(3.75rem, -6.25rem + 15.625vw, 12.5rem);
  }
}
