.Sliders {

  @media (min-width: 1280px) {
    margin-left: 20vw;
    display: grid;
    grid-template-columns: 4fr 1fr;
    grid-template-rows: auto;

    justify-content: center;
    align-content: center;

    height: 100%;
    width: calc(100vw - 20vw);
  }
}

.SliderA {

  .splide__slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    object-fit: cover;
    object-position: center;
    width: 100%;

    @media (min-width: 1280px) {
      object-fit: cover;
      width: 70%;
    }
  }
}
.SliderB {
  align-self: flex-end;
  width: 100%;

  @media (min-width: 1280px) {
    width: 15vw;
    margin-right: 10vw;
  }

  .splide__arrow {
    top: 10%;
    background: transparent;
  }

  .splide__arrow--prev {
    left: 40px;
    @media (min-width: 1280px) {
      left: 0;
    }
  }

  .splide__arrow--next {
    right: 40px;
    @media (min-width: 1280px) {
      right: 0;
    }
  }

  .splide__slide {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  p {
    text-align: center;
  }

  .Slide__title {
    font-size: 20px;
    font-weight: 500;
  }

  p:not(.Slide__title) {
    font-size: 16px;
    font-weight: 300;
  }

  p:not(:last-of-type) {
    padding-bottom: 10px;
  }
}
