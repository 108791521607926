.Home {
    position: relative;
    z-index: 0;
    height: 100vh;
    width: 100vw;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    @media (min-width: 1024px) {
        flex-direction: row;
    }

    .Navbar {
        display: none;

        @media (min-width: 1024px) {
            display: block;
        }
    }
}