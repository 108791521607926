/* COLORS MAP */
$colors: (
  "primary": (
    base: #EB887C,
    light: #EBACA4,
    dark: #EB563C,
  ),
  "secondary": (
    base: #7E83C5,
    light: #8E90AB
  ),
  "gray": (
    light1: #f4f6f8,
    light2: #eaedef,
    mid: #868b92,
    dark1: #a0a2a4,
    dark2: #eaedef,
  ),
  "black": (
    light1: #13171d,
    light2: #2d3239,
    base: #3b424b,
    dark1: #1c2026,
    dark2: #13171d,
  ),
  "white": (
    base: white,
  ),
);

:root {

  //Primary Color
  --c-pri: #F2F3F4;
  --c-pri_l: #F8F8F6;

  //Secondary Color
  --c-sec: #7E83C5;
  --c-sec_l: #8E90AB;

  //Terciary Color
  --c-ter: #FBF8EA;

  --bs-link-color: black;
  --bs-link-hover-color: #C37E85;
}

$body_bg: setcolor('white', 'base');
$body_color: setcolor('black', 'base');