@import '../functions/functions';
@import '../variables/variables';
@import '../variables/fonts';
@import '../variables/colors';

#root {
    white-space: pre-line;
  }

// html
html {
    overflow-x: hidden !important;
}

// body
body {
    position: relative;
    margin: 0;
    padding: 0;

    font-family: $body_font_family;
    font-weight: $body_font_weight;
    font-size: $body_font-size;

    background: $body_bg;
    color: $body_color;

    @include tablet {
        font-size: $body_font_size_ipad;
    }

    @include tablet-h {
        font-size: $body_font_size_desktop;
    }
}

// paragrapgh
p, span, button, textarea, select {
    font-family: $body_font_family;
    font-weight: $body_font_weight;

    color: $body_color;

    margin-bottom: 0;
}

input {
    padding: $inputs_padding_desktop;
    font-weight: $inputs_font_weight;
    font-family: $inputs_font_family;
    border-radius: $inputs_border_radius;

    &::placeholder {
        font-weight: $inputs_font_weight;
    }
}

// strong
strong {
    font-weight: setfont('weight', 'regular');
}

a {
    outline: none;
    text-decoration: none;
}

// headings
h1, h2, h3, h4, h5, h6, a, p, ul, li {
    padding: 0;
    margin: 0;
}