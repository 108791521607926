.Nav {
  --bs-accordion-color: black;
  --bs-accordion-bg: transparent;
  --bs-accordion-border-color: none;
  --bs-accordion-border-radius: 0;
  --bs-accordion-inner-border-radius: 0;
  --bs-accordion-btn-padding-x: 0;
  --bs-accordion-btn-padding-y: 10px;
  --bs-accordion-btn-color: black;
  --bs-accordion-btn-active-icon: var(--bs-accordion-btn-icon);
  --bs-accordion-btn-focus-border-color: transparent;
  --bs-accordion-btn-focus-box-shadow: none;
  --bs-accordion-active-color: black;
  --bs-accordion-active-bg: transparent;
  --bs-accordion-body-padding-x: 0;
  --bs-accordion-body-padding-y: 0;

  text-align: left;
  margin-top: 50px;

  display: flex;
  flex-direction: column;


  .accordion-button {
    display: flex;
    align-items: center;
  }

  &__header {
    p {
      font-weight: 700;
    }
  }

  &__links {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    a {
      font-weight: 400;
    }
  }
}
