@use "sass:map";

/* BRAKEPOINTS */
$breakpoints: (
  min: 400px,
  mobile: 576px,
  tablet: 768px,
  tablet-h: 1024px,
  screen-sm: 1280px,
  screen-md: 1440px,
  screen-lg: 1600px,
  screen-xl: 1900px,
  max: 1920px
);

/* FONTS */
$fonts: (
  "family": (
    "primary": "Raleway",
  ),
  "weight": (
    "light": 300,
    "book": 400,
    "medium": 500,
    "bold": 700,
  ),
);

/* Configuring min and max font size for clamp */
$font_size: (
  "h1": (
    "max": rem(58),
    "min": rem(24)
  ),
  "h2": (
    "max": rem(58),
    "min": rem(24)
  ),
  "h3": (
    "max": rem(58),
    "min": rem(24)
  ),
  "h4": (
    "max": rem(58),
    "min": rem(24)
  ),
  "h5": (
    "max": rem(58),
    "min": rem(24)
  ),
  "p": (
    "max": rem(58),
    "min": rem(24)
  ),
  "body": (
    "max": rem(58),
    "min": rem(24)
  )
);

$body_font_size: rem(14);
$body_font_size_ipad: rem(16);
$body_font_size_desktop: rem(14);


/* SPACES */
/* Margin and padding spaces */
$xs: rem(10);
$sm: rem(15);
$md: rem(30);
$lg: rem(60);
$xl: rem(120);

/* Paragraph & heading bottom margins */
$paragraph_margin: $sm;
$h1_margin: rem(20);
$h2_margin: rem(20);
$h3_margin: rem(20);
$h4_margin: rem(20);
$h5_margin: rem(20);
$h6_margin: rem(20);

/* Lateral margins & paddings for containers */
$horizontal_main_xs: rem(20);
$horizontal_main_sm: rem(20);
$horizontal_main_md: rem(40);
$horizontal_main_lg: rem(60);
$horizontal_main_xl: rem(50);
$horizontal_main_2xl: rem(100);
$horizontal_main_3xl: rem(150);

$horizontal_large_xs: rem(40);
$horizontal_large_sm: rem(40);
$horizontal_large_md: rem(80);
$horizontal_large_lg: rem(120);
$horizontal_large_xl: rem(300);
$horizontal_large_2xl: rem(300);
$horizontal_large_3xl: rem(300);

/* Top & bottom margins & paddings for containers */
$vertical_small_xs: rem(20);
$vertical_small_sm: rem(20);
$vertical_small_md: rem(20);
$vertical_small_lg: rem(30);
$vertical_small_xl: rem(30);
$vertical_small_2xl: rem(30);
$vertical_small_3xl: rem(45);

$vertical_medium_xs: rem(40);
$vertical_medium_sm: rem(50);
$vertical_medium_md: rem(50);
$vertical_medium_lg: rem(60);
$vertical_medium_xl: rem(70);
$vertical_medium_2xl: rem(80);
$vertical_medium_3xl: rem(100);

$vertical_large_xs: rem(40);
$vertical_large_sm: rem(50);
$vertical_large_md: rem(60);
$vertical_large_lg: rem(120);
$vertical_large_xl: rem(120);
$vertical_large_2xl: rem(120);
$vertical_large_3xl: rem(150);


/* BUTTONS */
$buttons_font_family: setfont("family", "primary");
$buttons_font_weight: setfont('weight', 'bold');
$buttons_border_radius: rem(100);

$buttons_font_size: rem(12);
$buttons_font_size_ipad: rem(12);
$buttons_font_size_desktop: rem(12);

$buttons_padding: $sm $md;
$buttons_padding_ipad: $sm $md;
$buttons_padding_desktop: $sm $md;


/* INPUTS */
$inputs_font_family: setfont("family", "primary");
$inputs_font_weight: setfont('weight', 'bold');
$inputs_color: setcolor('secondary', 'dark');

$inputs_border_radius: rem(20);

$inputs_font_size: rem(14);
$inputs_font_size_ipad: rem(16);
$inputs_font_size_desktop: rem(14);

$inputs_padding: 0 rem(10);
$inputs_padding_ipad: 0 $sm;
$inputs_padding_desktop: 0 rem(20);