.Menu {
  width: 100vw;
  position: static;
  
  @media (min-width: 1280px) {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    
    height: 100vh;
    width: clamp(16rem, 0rem + 20vw, 24rem);
  }
}

.breakpoint__screenSm-up {
  height: 100%;
}

.MenuDesktop {
  display: none;
  height: 100%;
  width: 100%;
  background-color: var(--c-pri);

  flex-direction: column;
  justify-content: space-between;

  padding: clamp(2rem, 1rem + 1.25vw, 2.5rem);

  @media (min-width: 1280px) {
    display: flex;
  }

  .MenuDesktop__titulo p {
    font-weight: 700;
    line-height: 0.8em;
    text-align: left;
  }
}
