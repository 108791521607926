.Banner {


    width: 100%;
    height: 100vh;

    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: 1024px) {
        margin-left: 20vw;
    }

    .SlideItem {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img {
        object-position: center;
        object-fit: contain;
        height: 40vh;

        @media (min-width: 768px) {
            height: 80vh;
        }
        
        @media (min-width: 1024px) {
            height: 100vh;
        }
    }
}