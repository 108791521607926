.Card {
    position: relative;
    height: 100%;
    width: 100%;

    &__bottom{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      text-align: center;

      &--title {
          padding-top: 15px;

          font-size: 12px;
          font-weight: 400;
          line-height: 1em;

          @media (min-width: 768px) {
            font-size: 14px;
          }
          @media (min-width: 1600px) {
            font-size: 16px;
          }
      }
      &--material {
          padding-top: 10px;

          font-size: 11px;
          font-weight: 300;
          line-height: 1em;
          color: #8A8C8E;

          @media (min-width: 768px) {
            font-size: 12px;
          }
          @media (min-width: 1600px) {
            font-size: 13px;
          }
      }


    }

    &__img {
      position: relative;
      top: 0;
      left: 0;
      z-index: 0;

      &::before {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        background-color: rgba(227, 227, 227, 0.2);
        height: 100%;
        width: 100%;
        content: '';
      }

      img {
        object-fit: cover;
        object-position: center;
        width: 100%;
      }

      .Card__hover {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.3);
        height: 100%;
        width: 100%;

        opacity: 0;
        transition: opacity 0.4s ease-out;

        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          opacity: 1;
          transition: opacity 0.4s ease-out;
        }

        img {
          width: 40px;
        }
      }
    }
  }
