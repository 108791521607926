.SliderInv {
  margin-bottom: 40px;
  margin-top: 20px;

  padding-left: clamp(1.875rem, 0.58rem + 5.525vw, 5rem);
  padding-right: clamp(1.875rem, 0.58rem + 5.525vw, 5rem);

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;

  justify-content: center;
  align-content: center;

  @media (min-width: 1280px) {
    margin-left: 15vw;
    margin-bottom: 80px;
    margin-top: 80px;

    padding-left: clamp(3.75rem, -6.25rem + 15.625vw, 12.5rem);
    padding-right: clamp(7.5rem, -8.214rem + 24.554vw, 21.25rem);

    grid-template-columns: 6fr 4fr;
    grid-template-rows: auto;

    height: 100%;
  }
}

.SliderC {
  width: 100%;
  
  @media (min-width: 1280px) {
    border-radius: 5px;
    border: 30px solid #f2f3f4;
  }

  .splide__slide {
    display: flex;
    justify-content: center;
    align-items: center;
    
    img {
      object-fit: cover;
      object-position: center;
      width: 100%;

    }
  }
}
.SliderD {
  margin-top: 40px;
  align-self: flex-end;
  width: 100%;

  @media (min-width: 1280px) {
    width: 15vw;
    margin-top: 0;
  }

  .splide__arrow {
    top: 12%;
    background: transparent;
  }

  .splide__arrow--prev {
    left: 50px;
    @media (min-width: 1280px) {
      left: 0;
    }
  }

  .splide__arrow--next {
    right: 50px;
    @media (min-width: 1280px) {
      right: 0;
    }
  }

  .splide__slide {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  p {
    text-align: center;
  }

  .Slide__title {
    font-size: 20px;
    font-weight: 500;
  }

  p:not(.Slide__title) {
    font-size: 16px;
    font-weight: 300;
  }

  p:not(:last-of-type) {
    padding-bottom: 0;
    @media (min-width: 1280px) {
      padding-bottom: 10px;
    }
  }
}
