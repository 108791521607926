.Escultura {
    width: 100%;
    overflow: hidden;

    .Navbar {
      display: none;

      @media (min-width: 1024px) {
        display: block;
      }
    }
  }